<template>
  <section class="a-login fill-height">
    <v-container fluid class="fill-height">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" lg="8" xl="6">
          <div class="overflow-hidden rounded-3">
            <v-row no-gutters class="d-flex align-center justify-center">
              <v-col cols="12" md="6" align-self="stretch">
                <div
                  class="d-flex align-center justify-center primary fill-height"
                >
                  <div class="a-login__logo py-8 py-md-0">
                    <img src="@/assets/logo--header.png" alt="AzfiberNet" />
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="6" align-self="stretch">
                <div class="a-login__content white fill-height">
                  <LoginForm
                    @formSubmit="handleFormSubmit"
                    :isFormSubmitting="isFormSubmitting"
                  />
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import API from "@/services";
import LoginForm from "./components/LoginForm.vue";
import bus from "../../../event-bus";

export default {
  components: {
    LoginForm,
  },
  data() {
    return {
      isFormSubmitting: false,
    };
  },
  methods: {
    ...mapActions({
      setAccessToken: "setAccessToken",
      setRefreshToken: "setRefreshToken",
      setUserData: "setUserData",
    }),
    handleFormSubmit({ username, password }) {
      this.isFormSubmitting = true;

      API.logIn({ username, password })
        .then(async (response) => {
          this.setAccessToken(response.accessToken);
          this.setRefreshToken(response.refreshToken);
          this.setUserData(response.user);

          const { query } = this.$router.currentRoute;
          this.$router.push(query.redirect ? query.redirect : "/");
          bus.$emit("notificationMessage", {
            text: "Uğurla daxil oldunuz",
            color: "green",
          });
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            text: "İstifadəçi adı və ya şifrə düzgün daxil edilməyib.",
            color: "error",
          });
        })
        .finally(() => {
          this.isFormSubmitting = false;
        });
    },
    async getUserData() {
      return await API.getUserData();
    },
  },
};
</script>

<style lang="scss">
.a-login {
  background: rgba(0, 0, 0, 0.12);

  &__content {
    padding: 86px 52px;

    .row {
      margin: 0;

      .col:last-child {
        margin: 0;
      }

      .col {
        margin-bottom: 4px;
        padding-left: 8px !important;
        padding-right: 8px !important;
      }
    }
  }
  &__logo {
    & img {
      height: 200%;
      object-fit: contain;
    }
  }
}
</style>
